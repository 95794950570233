import React from "react"
import { graphql } from "gatsby"
import { useLocale } from "../hooks/useLocale"
import DeepdivePage from "../components/deepdive/deepdive-page"

// Types
import { IDeepdiveCard } from "../prismic-types/slices/deep-dive-cards"

const Deepdive = (props: any) => {
  const { isDutch } = useLocale()

  // Extracting content from the GraphQL query
  const content = props.data.prismicDeepdiveLandingPage.data
  const deepDiveTemplates = props.data.allPrismicDeepDiveTemplate.edges

  // Getting the heading and description from the landing page content
  const heading = content.heading.raw
  const description = content.description

  // Mapping the fetched deep dive template data to the IDeepdiveCard format
  const cards: IDeepdiveCard[] = deepDiveTemplates.map((template: any) => {
    const data = template.node.data
    return {
      label: data.heading.text,
      description: data.description,
      link: {
        label: isDutch ? "Meer weten" : "Learn more", // Assuming a static label since it's not provided in the data
        href: `/deepdive/${template.node.uid}`, // Constructing a link based on the node ID
        color: data.heading_color, // Using heading_color for link color as a placeholder
      },
      backgroundColor: data.background_color,
      foregroundColor: data.foreground_color,
    }
  })

  return <DeepdivePage data={{ title: heading, description, cards }} />
}

export const query = graphql`
  query deepdivePage($locale: String!) {
    prismicDeepdiveLandingPage(lang: { eq: $locale }) {
      id
      data {
        description
        heading {
          raw
          text
        }
      }
    }
    allPrismicDeepDiveTemplate(
      filter: { lang: { eq: $locale } }
      sort: { fields: data___position, order: ASC }
    ) {
      edges {
        node {
          id
          uid
          data {
            heading {
              text
            }
            background_color
            foreground_color
            heading_color
            description
          }
        }
      }
    }
  }
`

export default Deepdive
